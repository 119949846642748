<script setup>
import { provide } from "vue";
import { useScreenGroup } from "./useScreenGroup";

const screenGroup = ref(useScreenGroup());

provide("screenGroup", screenGroup.value);

const goNext = (p) => screenGroup.value.goNext(p);
const goPrev = (p) => screenGroup.value.goPrev(p);
const goHome = (p) => screenGroup.value.goHome(p);
const getCurrentScreen = () => screenGroup.value.getCurrentScreen();

defineExpose({
  goNext,
  goPrev,
  goHome,
  getCurrentScreen,
});
</script>

<template>
  <div class="v-screen-group">
    <slot :goPrev="goPrev" :goNext="goNext" :goHome="goHome"></slot>
  </div>
</template>

<style scoped>
.v-screen-group {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  overscroll-behavior: contain;
  overflow: hidden;
}
</style>
